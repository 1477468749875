*{
  box-sizing: border-box;
  
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a
{
  text-decoration: none;
  color: inherit;
}    
    /* navbar Start */

   .nav-section{
  background: linear-gradient(90deg, #b86cffa2, #060c21, #00e1ff88);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  
}


.nav{
  display: flex;
  height: 3.5em;
  justify-content: space-between;
  /* background-color: rgba(0,0,0,0.3); */
}
.nav-responsive{
  height: 4em;
  display: flex;
  justify-content: space-between;
}
.nav-links{
    padding-right: 9rem;
    color: aliceblue;
}
.nav-links-responsive{
   padding-right: 9rem;
   color: aliceblue;
  
}
ul {
  list-style-type: none;
}
.nav-links li{
  display: inline;
  padding-left: 3.5rem;
  font-size: 1.3rem;
}
.nav-links-responsive li{
  display: inline;
  padding-left: 3.5rem;
  font-size: 1.7rem;
}
.nav img{
  height: 100%;
  /* width: 100%; */
}

.burger-btn{
  
  display: none;
  
}
/* navBar end */
body{
  background-color: lightgray;
}

/* Landing page start*/
.landing-section{
  /* position: absolute; */
  top: 0;
  left: 0;
  
  width: 100%;
  height: 95vh;  /*viewport height viewable window/screen*/
  z-index: -1; /*specifies the stack order of an element. An element with greater stack order is always in front of an element with a lower stack order*/
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
}

.landing-details{
  font-size: 1.5rem;
  color: aliceblue;
  display: flex;
  height: 95vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 3.5em;
}
.landing-details p{
    text-align: center;
    width: 60%;
}
.scrolldown-btn{
    color: aquamarine;
    background: transparent;
    border-color: transparent;
    font-size: xxx-large;
    
    align-self:center;              
    bottom:0; 
    cursor: pointer;                       
    
}
/* Landingpage end */

/* Projects page start */
.allprojects-div{
  /* position: relative; */
  width: 100%;
  padding-top: 1rem;
  /* padding-left: 2em; */
  /* padding-right: 2rem; */
  background-color: aliceblue;
}
.allprojects-div{
  text-align: center;
}
.project-div{
    display: grid;
    grid-template-columns: 40% auto;
    height: 95vh;
    border: 1px solid;
   
}
.project-images{
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding-top: 1rem;
    margin-right: 3rem;
    /* padding-left: 2rem; */
    margin-left: 3rem;
}
.project-images img{
  overflow: hidden;
  width: 40%;
}
.project-images img:nth-of-type(1){
  align-self: start;
}
.project-images img:nth-of-type(2){
  align-self: end;
  padding-right: 0rem;
}
.project-images img:nth-of-type(3){
  align-self: start;
}
.project-details{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.project-details p{
  font-size: 1.3rem;
  text-align: center;
}
.skills-btns{
  
  margin-top: 2em;

}
.skills-btns button{
  margin-right: 0.95rem;
  font-size: 1.0rem;
  padding: 0.8rem;
  border-radius: 0.4rem;
  
}
.external-links{
  align-self: center;
  /* margin-left: -2rem; */
  padding: 0;
  font-size: 1.3rem;
}
.external-links svg{
  height: 1.5em;
  width: 1.5em;
}
.external-links li{
  display: inline;
  padding-right: 3rem;
  color: blue;
}
/* Projects Page End */

/* Skills page Start */
.skills-div{
  height:95vh;
  width: 100%;
  background-color: #0f1b2e;
  color: darkgray;
  padding: 3rem;
  display: grid;
  grid-template-columns: 45% auto;
  
  /* overflow: hidden; */
}
.skills-list{
  display: flex;
  
  flex-direction: column;
  font-size: 1.4rem;
  border-right: 2px solid #5e3434;
  border-bottom: 2px solid #5e3434;
}
.skills-list h2{
  align-self: center;
}
.skills-list ul{
  display: flex;
  justify-content: space-evenly;
  padding-left: 0;
  
}
.skills-list svg{
  font-size: xxx-large;
}
.about-me{
  display: flex;  
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 1.4rem;
  border-bottom: 2px solid #5e3434;
  padding-left: 1rem;
}

/* Skills page end */

/* contact form start*/
.footer{
  width: 100%;
  
  display: grid;
  background-color: #14204a;
  color: lightgray;
  grid-template-columns: 60% auto;
  padding: 20px;
}
.contact-form {
  border-radius: 5px;
  background-color: #f2f2f2;
  color: black;
  padding: 1rem;
}
input[type=text], input[type=email], select, textarea {
  width: 100%; /* Full width */
  padding: 12px; /* Some padding */ 
  border: 1px solid #ccc; /* Gray border */
  border-radius: 4px; /* Rounded borders */
  margin-top: 6px; /* Add a top margin */
  margin-bottom: 16px; /* Bottom margin */
  resize: vertical /* Allow the user to vertically resize the textarea (not horizontally) */
}


input[type=submit] {
  background-color: #04AA6D;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}


input[type=submit]:hover {
  background-color: #45a049;
}
.socials ul{
  display: flex;
  justify-content: space-evenly;
  padding-left: 0;
  border-bottom: 1px solid #5E341A;
}
.socials{
  display: flex;
  flex-direction: column;
  font-size: 1.3rem;
}
.socials h2{
  align-self: center;
  text-align: center;
}
.socials svg{
  font-size: xxx-large;
}
.socials p{
  
  padding-top: 3rem;
  text-align: center;
}
.socials p span{
  display: block;
  text-align: center;
  
}
/* contact form end */

@media (max-width: 950px) {
  .project-div, .skills-div, .footer { 
    grid-template-columns: 1fr;}

  .landing-details p{
    width: 80%;    
  }
  .landing-details h2{
    text-align: center;
  }
    
  .nav-links{
    display: none;   
    
  } 
  .nav-links-responsive{
    display: block;
    padding-right: 0;
    margin-top: 2.2em;
    margin-right: 3.6rem;
    color: aliceblue;
    
  }
  .nav-links-responsive li{
    display: block;
    margin-top: 0.4em;
    font-size: 1.4rem;
  }
  .nav-responsive{
    height: fit-content;
    display: flex;
    justify-content: space-between;
    background-color: rgba(0,0,0,0.5);
  }
  .nav-responsive img{
    width: 100%;
  }
  .burger-btn{
    display: block;
    position: absolute;
    background: transparent;
    border-color: transparent;
    top: 0;
    cursor:pointer;
    right: 0;    
  }
  .burger-svg, .close-menu-svg{
    color: white;
    font-size: xxx-large;
  }
  .project-div{
    height: fit-content;
    gap: 2em;
    margin-bottom: 0.1em;
  }
  .project-images{
    display: flex;
    flex-direction: row;
  }
  .project-images img{
    overflow: hidden;
    width: 40%;
  }
  .project-images img:nth-of-type(1){
    align-self: start;
    margin-top: 1.5em;
    margin-right: 1em;
  }
  .project-images img:nth-of-type(2){
    align-self: start;
    padding-right: 0rem;
    margin-right: 1em;
    box-shadow: 5px 5px 25px rgb(27, 17, 17);
    width: 80%;
  }
  .project-images img:nth-of-type(3){
    align-self: start;
    margin-top: 1.5em;
  }
  .skills-div{
    padding: 1.5rem;
    height: fit-content;
  }
 
  .skills-btns button{
    margin-right: 0.95rem;
    font-size: 0.9rem;
    padding: 0.5rem;
    border-radius: 0.4rem;
    
  }
  .skills-list{
    border-right:0px;
  }
  .about-me{
    padding-left: 0;
  }
  .external-links{
    align-self: center;
    /* margin-left: -2rem; */
    padding: 0;
    font-size: 1.1rem;
  }
  .footer{
    height: fit-content;
  }
}
@media(min-width: 1800px){
  .project-images img{
    overflow: hidden;
    width: 36%;
  }
  .project-details p{
    font-size: 1.5rem;
  } 
  .project-details h2{
    font-size: 2.0rem;
  } 
  .skills-div p, .skills-div li{
    font-size: 1.6rem;
  }
  .skills-div h2{
    font-size: 1.7rem;
  }
  .nav{
    
    height: 4em;
  }
  .nav-links li{
    
    font-size: 1.7rem;
  }
}